import {getWrappedComponent} from '../../../appConfig';
import {sliderGlobalPropsStrategy} from '../../../sliderGallery/sliderGlobalStrategy';
import styleParams from '../stylesParams';
import {SliderGallery} from '../../../sliderGallery/components/SliderGallery/SliderGallery';
import {SliderGallery as SliderGalleryOld} from '../../../sliderGallery/components/SliderGalleryOld/SliderGallery';
import {withExperiment} from '../../../withExperiment';
import {Experiments} from '../../../constants';

export default getWrappedComponent({
  Component: withExperiment(SliderGallery, SliderGalleryOld, Experiments.NewSliderGallery),
  stylesParams: styleParams,
  globalPropsStrategy: sliderGlobalPropsStrategy,
  sliderGallery: true,
});
