import React from 'react';
import {useExperiments} from '@wix/yoshi-flow-editor';

export const withExperiment = (
  ComponentWhenTrue: React.ComponentType,
  ComponentWhenFalse: React.ComponentType,
  experimentName: string
) => {
  return (props) => {
    const {experiments} = useExperiments();

    if (experiments.enabled(experimentName)) {
      return <ComponentWhenTrue {...props} />;
    }

    return <ComponentWhenFalse {...props} />;
  };
};
